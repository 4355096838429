<template>
  <b-modal
    id="modal-user"
    ok-title="submit"
    cancel-variant="outline-secondary"
    size="md"
    title="Add User"
    body-class="common_modal"
    no-close-on-backdrop
    @ok.prevent="handleSubmit"
  >

    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <b-form class="mt-1">
        
          <b-form-group
            label-cols-sm="3"
            label-cols="12"
            class="required"
            label="User"
            label-for="user_id"
          >
            <div class="form-col-select">
              <v-select
                v-model="user_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="userOptions"
                :clearable="false"
                label="name"
                :reduce="(option) => option.id"
                placeholder="Select User"
                
              />
              <feather-icon size="18" icon="ChevronDownIcon" />
            </div>
          </b-form-group>
      </b-form>
    </b-overlay>
  </b-modal>
</template>
    <script>
import {
  BButton,
  BModal,
  VBModal,
  BCardText,
  BOverlay,
  BForm,
  BFormGroup,
  BFormInput,
  BFormDatepicker,
  BRow,
  BCol,
  BFormInvalidFeedback,
  BCardBody,
  BTable,
  BCard,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ref } from "@vue/composition-api";
import store from "@/store";
import vSelect from "vue-select";
// import { ValidationProvider, ValidationObserver } from "vee-validate";
// import formValidation from "@core/comp-functions/forms/form-validation";
export default {
  data() {
    return {
      user_id: null,
    };
  },
  components: {
    BButton,
    BModal,
    VBModal,
    BCardText,
    BOverlay,
    BForm,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BRow,
    BCol,
    BFormInvalidFeedback,
    BCardBody,
    BTable,
    BCard,
    vSelect,
    // ValidationProvider,
    // ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    itemData: {
      type: Object,
      required: true,
    },
    userOptions: {
      type: Array,
      required: true,
    },
  },
  methods: {
    hideModal() {
      this.$bvModal.hide("modal-user");
    },
    handleSubmit() {
      // this.loading = !this.loading
      // console.log(this.itemData);
      this.$swal({
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonText: "Save",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("app-user/addStaffToCertificate", {
              course_record_id: this.itemData.id,
              user_id: this.user_id,
            })
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
              this.hideModal();
              //this.itemData = response.data.itemData
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    onChange(file, fileLists) {
      this.itemData.image_files = [];
      fileLists.forEach((item) => {
        this.itemData.image_files.push(item.raw);
      });
      console.log("image_files", this.itemData.image_files);
    },
  },
  setup(props, { emit }) {
    const uploadUrl = ref(
      process.env.VUE_APP_SERVICE_URL + "/api/project/uploadFile"
    );
    const headerObj = {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    };
    const loading = ref(false);

    // const { refFormObserver, getValidationState, resetForm } =
    //   formValidation(resetuserData);

    return {
      // refFormObserver, 
      // getValidationState, 
      // resetForm,
      loading,
      uploadUrl,
      headerObj,
    };
  },
};
</script>
    
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";


.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100%;
  height: 100%;
  line-height: 150px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
  border-radius: 0%;
}
.hideUpload > div {
  display: none;
}
</style>