<template>
  <b-modal
    id="modal-upload"
    ok-title="submit"
    cancel-variant="outline-secondary"
    scrollable
    size="lg"
    title="Upload Image"
    no-close-on-backdrop
    @ok.prevent="handleSubmit"
  >
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <b-form class="mt-1">
        <b-form-group
          class="required"
          label="ID"
          label-for="id"
          label-cols-md="4"
          style="display: none"
        >
          <b-form-input id="id" v-model="itemData.id" disabled />
        </b-form-group>
        <b-row class="m-0 w-100">
          <b-col
            cols="12"
            class="d-flex align-item-center justify-content-center"
          >
            <el-upload
              class="avatar-uploader"
              list-type="picture-card"
              ref="upload_image"
              :action="uploadUrl"
              :headers="headerObj"
              name="image_files"
              multiple
              :limit="10"
              :data="itemData"
              :on-change="onChange"
              :on-remove="onChange"
              :auto-upload="false"
            >
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </b-modal>
</template>
  <script>
import {
  BButton,
  BModal,
  VBModal,
  BCardText,
  BOverlay,
  BForm,
  BFormGroup,
  BFormInput,
  BFormDatepicker,
  BRow,
  BCol,
  BFormInvalidFeedback,
  BCardBody,
  BTable,
  BCard,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ref } from "@vue/composition-api";
import store from "@/store";
export default {
  components: {
    BButton,
    BModal,
    VBModal,
    BCardText,
    BOverlay,
    BForm,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BRow,
    BCol,
    BFormInvalidFeedback,
    BCardBody,
    BTable,
    BCard,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    itemData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    hideModal() {
      this.$bvModal.hide("modal-upload");
    },
    handleSubmit() {
      // this.loading = !this.loading
      // console.log(this.itemData);
      this.$swal({
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonText: "Save",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          let formData = new FormData();
          // formData.append('image_files', this.itemData.image_files)
          if (this.itemData.image_files) {
            this.itemData.image_files.forEach((file) => {
              formData.append("image_files[]", file);
            });
          }
          formData.append("id", this.itemData.id);
          this.loading = true;
          store
            .dispatch("app-user/uploadCertificate", formData)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
              this.hideModal();
              //this.itemData = response.data.itemData
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    // onChange(file, fileLists) {
    //   this.itemData.image_files = [];
    //   fileLists.forEach((item) => {
    //     this.itemData.image_files.push(item.raw);
    //   });
    //   console.log("image_files", this.itemData.image_files);
    // },
    onChange(file, fileLists) {
      this.itemData.image_files = [];
      fileLists.forEach((item) => {
        console.log(item.raw)
        if (
          item.raw.type ==
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
          // word file ".docx"
          item.url = process.env.VUE_APP_IMAGE_URL + "icon/word.png";
        } else if (
          item.raw.type ==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
          // excel file ".xlsx"
          item.url = process.env.VUE_APP_IMAGE_URL + "icon/excel.png";
        } else if (item.raw.type == "application/pdf") {
          // pdf file ".pdf"
          item.url = process.env.VUE_APP_IMAGE_URL + "icon/pdf.png";
        } 
        // else if (
        //   item.raw.type != "image/jpeg" &&
        //   item.raw.type != "image/jpg" &&
        //   item.raw.type != "image/png" &&
        //   item.raw.type != "image/gif" &&
        //   item.raw.type != "image/webp" &&
        //   item.raw.type != "image/svg"
        // ) {
        //   // files other than images
        //   item.url = process.env.VUE_APP_IMAGE_URL + "icon/others.jpeg";
        // }
        this.itemData.image_files.push(item.raw);
      });
      console.log("image_files", this.itemData.image_files);
    },
  },
  setup(props, { emit }) {
    const uploadUrl = ref(
      process.env.VUE_APP_SERVICE_URL + "/api/project/uploadFile"
    );
    const headerObj = {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    };
    const loading = ref(false);
    return {
      loading,
      uploadUrl,
      headerObj,
    };
  },
};
</script>
  
  <style lang="scss">
.el-upload-list--picture-card .el-upload-list__item{
  margin: 0 8px 0px 0;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100%;
  height: 100%;
  line-height: 150px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
  border-radius: 0%;
}
.hideUpload > div {
  display: none;
}
</style>