<template>
  <div id="user_form" v-if="courseData">
    <modal-upload :item-data="courseData" @refetch-data="refetchData" />
    <modal-user
      :item-data="courseData"
      :user-options="userOptions"
      @refetch-data="refetchData"
    />
    <div class="d-flex">
      <h3 class="mb-0 ml-0 color4 font-weight-bolder">Edit Course Record</h3>
    </div>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <!-- Form: Personal Info Form -->
        <b-form class="mt-1" @submit.prevent="handleSubmit(onSubmit)">
          <b-row class="mt-2 mt-sm-3">
            <!-- Field: License For-->
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="License For"
                rules="required"
              >
                <b-form-group
                  label-cols-sm="3"
                  label-cols="12"
                  class="required"
                  label="License For"
                  label-for="license_authority_id"
                >
                  <div class="form-col-select">
                    <v-select
                      v-model="courseData.authority_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="licenseAuthorityOptions"
                      :clearable="false"
                      label="authority_name"
                      :reduce="(option) => option.id"
                      placeholder="Select License For"
                    />
                    <feather-icon size="18" icon="ChevronDownIcon" />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Organiser -->
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Organiser"
                rules="required"
              >
                <b-form-group
                  class="required"
                  label="Organiser"
                  label-for="course_organization_id"
                  label-cols-sm="3"
                  label-cols="12"
                >
                  <div class="form-col-select">
                    <v-select
                      v-model="courseData.course_organization_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="courseOrganizationOptions"
                      :clearable="false"
                      label="name"
                      :reduce="(option) => option.id"
                      placeholder="Select Organization"
                    />
                    <feather-icon size="18" icon="ChevronDownIcon" />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Organiser Name -->
            <b-col
              cols="12"
              md="6"
              v-if="courseData.course_organization_id == 7"
            >
            </b-col>
            <b-col
              cols="12"
              md="6"
              v-if="courseData.course_organization_id == 7"
            >
              <validation-provider
                #default="validationContext"
                name="Organiser Name"
                rules="required"
              >
                <b-form-group
                  label-cols-sm="3"
                  label-cols="12"
                  class="required"
                  label="Organiser Name"
                  label-for="Organiser Name"
                >
                  <b-form-input
                    id="Organiser Name"
                    v-model="courseData.organization_name"
                    placeholder="Enter Organiser Name"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <hr class="w-100" />

            <!-- Field: Topic -->
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Topic"
                rules="required"
              >
                <b-form-group
                  class="required"
                  label="Topic"
                  label-for="topic"
                  label-cols-sm="3"
                  label-cols="12"
                >
                  <b-form-input
                    id="topic"
                    v-model="courseData.topic"
                    :state="getValidationState(validationContext)"
                    placeholder="Enter Course Topic"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Course Date-->
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Course Date"
                rules="required"
              >
                <b-form-group
                  label-cols-sm="3"
                  label-cols="12"
                  class="required"
                  label="Course Date"
                  label-for="course_date"
                >
                  <flat-pickr
                    v-model="courseData.course_date"
                    class="form-control"
                    :config="flatDateConfig"
                    placeholder="YYYY-MM-DD"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <hr class="w-100" />

            <!-- Field: Ethics & Compliance -->
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Ethics & Compliance"
                rules="required"
              >
                <b-form-group
                  label-cols-sm="3"
                  label-cols="12"
                  class="required"
                  label="Ethics & Compliance"
                  label-for="ethics_compliance"
                >
                  <div class="form-col-select">
                    <v-select
                      v-model="courseData.ethics_compliance"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="genderOptions"
                      :clearable="false"
                      label="name"
                      :reduce="(option) => option.id"
                      placeholder="Yes/No"
                    />
                    <feather-icon size="18" icon="ChevronDownIcon" />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Proposed Hours-->
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Proposed Hours"
                rules="required"
              >
                <b-form-group
                  label-cols-sm="3"
                  label-cols="12"
                  class="required"
                  label="Proposed Hours"
                  label-for="counted_hours"
                >
                  <b-form-input
                    id="counted_hours"
                    v-model="courseData.counted_hours"
                    placeholder="Enter Proposed Hours"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <hr class="w-100" />

            <!-- Field: Relevant Topic -->
            <b-col
              v-if="
                courseData.authority_id == 1 || courseData.authority_id == 'b'
              "
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="Relevant Topic"
                rules="required"
              >
                <b-form-group
                  label-cols-sm="3"
                  label-cols="12"
                  class="required"
                  label="Relevant Topic"
                  label-for="relevant_topic"
                >
                  <div class="form-col-select">
                    <v-select
                      v-model="courseData.relevant_topic"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="genderOptions"
                      :clearable="false"
                      label="name"
                      :reduce="(option) => option.id"
                      placeholder="Yes/No"
                    />
                    <feather-icon size="18" icon="ChevronDownIcon" />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </b-form-group>
              </validation-provider>
            </b-col>

            <hr class="w-100" />

            <b-col cols="12" md="6">
              <h3 class="mb-1 ml-0 color4 font-weight-bolder">
                Compliance Approval on User Record
              </h3>
            </b-col>
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-end"
            >
              <b-button variant="primary" @click="handleUser">
                Add User</b-button
              >
            </b-col>
            <!-- Field: Staff Attended-->
            <b-row
              style="margin-left: 0px"
              class="w-100 align-items-center mt-2"
              v-for="(staff, index) in courseData.staff_records"
              :key="'staff_records' + index"
            >
              <b-col class="mb-1 mb-sm-0" cols="12" sm="5" lg="4">
                <b-badge class="staff-badge mobile-w100">{{
                  staff.name +
                  " - " +
                  staff.email +
                  " - " +
                  staff.license_authority_name
                }}</b-badge>
              </b-col>
              <b-col class="mb-1 pr-0 mb-sm-0" cols="7" sm="5" lg="4">
                <validation-provider
                  #default="validationContext"
                  name="Staff Attended"
                  rules="required"
                >
                  <!-- <b-form-checkbox-group
                    v-model="selectedStaffs"
                    :options="courseData.staff_records"
                    value-field="id"
                    text-field="name_email"
                    disabled-field="notEnabled"
                    class="demo-inline-spacing"
                    stacked
                  /> -->
                  <div class="form-col-select">
                    <v-select
                      v-model="staff.approved_bit"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="approved_options"
                      :clearable="false"
                      label="name"
                      :reduce="(option) => option.id"
                      placeholder="Approve Status"
                    />
                    <feather-icon size="18" icon="ChevronDownIcon" />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </validation-provider>
              </b-col>
              <b-col class="mb-1 mb-sm-0" cols="5" sm="2" lg="4">
                <b-button
                  class="mobile-w100"
                  @click="confirmDeleteStaff(staff.id)"
                  >Delete</b-button
                >
              </b-col>
            </b-row>
            <hr class="w-100" />

            <!-- Field: User Course Certificate -->
            <b-col cols="12" md="6">
              <h3 class="mb-1 ml-0 color4 font-weight-bolder">
                User Course Certificate
              </h3>
            </b-col>
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-end"
            >
              <b-button
                variant="primary"
                style="border-color: #30db7c !important"
                @click="handleUpload"
              >
                Add Certificate</b-button
              >
            </b-col>

            <b-col
              cols="12"
              md="3"
              class="mt-2"
              v-for="(document, index) in courseData.course_documents"
              :key="'course_documents' + index"
            >
              <a
                v-if="document.file_path.endsWith('pdf')"
                target="_blank"
                :href="document.file_path"
              >
                <el-image
                  style="width: 100%; aspect-ratio: 2 /1; padding: 0"
                  :src="require('@/assets/images/icons/pdf.png')"
                ></el-image>
              </a>
              <a
                v-else-if="document.file_path.endsWith('docx')"
                target="_blank"
                :href="document.file_path"
              >
                <el-image
                  style="width: 100%; aspect-ratio: 2 /1; padding: 0"
                  :src="require('@/assets/images/icons/file-icons/word.png')"
                ></el-image>
              </a>
              <el-image
                v-else
                style="width: 100%; aspect-ratio: 2 /1; padding: 0"
                fit="cover"
                :src="document.file_path"
                :preview-src-list="documentList"
              >
              </el-image>
              <b-button
                variant="danger"
                class="delete-button"
                @click="confirmDelete(document.id)"
                >Delete</b-button
              >
            </b-col>
            <hr class="w-100" />
          </b-row>
          <!-- Button: Submit & Reset Button.-->
          <div class="d-flex mt-1 flex-wrap flex-sm-nowrap justify-content-end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-sm-2 mobile-w100"
              type="submit"
            >
              <feather-icon size="16" class="mr-1" icon="CheckCircleIcon" />
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
              class="mt-1 mt-sm-0 mobile-w100"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </b-overlay>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BBadge,
  BOverlay,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BFormCheckbox,
  BFormInvalidFeedback,
  BImg,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { ref, onMounted } from "@vue/composition-api";
import vSelect from "vue-select";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import "animate.css";
import { heightTransition } from "@core/mixins/ui/transition";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, alphaNum, email } from "@validations";
import ModalUpload from "../modal/ModalUpload.vue";
import ModalUser from "../modal/ModalUser.vue";

export default {
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  components: {
    BRow,
    BCol,
    BForm,
    BBadge,
    BOverlay,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    BImg,
    ModalUpload,
    ModalUser,
  },
  directives: {
    Ripple,
  },
  methods: {
    refetchData() {
      this.$emit("refetch-data");
    },
    handleUser() {
      this.$bvModal.show("modal-user");
    },
    handleUpload() {
      this.$bvModal.show("modal-upload");
    },
    hide() {
      this.$router.replace("/users/course/list");
    },
    confirmDeleteStaff(id) {
      this.$swal({
        title: "Are you sure?",
        text: "Your action is final and you will not be able to retrieve the staff record.",
        showCancelButton: true,
        confirmButtonText: "Delete",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("app-user/deleteStaffRecord", { id: id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.refetchData();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    confirmDelete(id) {
      this.$swal({
        title: "Are you sure?",
        text: "Your action is final and you will not be able to retrieve the file.",
        showCancelButton: true,
        confirmButtonText: "Delete",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("app-user/deleteCertificate", { id: id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.refetchData();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    onSubmit() {
      console.log("submit data", this.courseData);
      if (this.courseData.course_organization_id != 7) {
        this.courseData.organization_name = null;
      }
      this.$swal({
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonText: "Save Changes",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          store
            .dispatch("app-user/updateCourseRecord", this.courseData)
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$router.go(-1);
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  initTrHeight() {
    this.trSetHeight(null);
    this.$nextTick(() => {
      this.trSetHeight(this.$refs.form.scrollHeight);
    });
  },
  props: {
    courseData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const licenseAuthorityOptions = ref([]);
    const courseOrganizationOptions = ref([]);
    const documentList = ref([]);

    const approved_options = [
      {
        id: -1,
        name: "Rejected",
      },
      {
        id: 1,
        name: "Approved",
      },
      {
        id: 0,
        name: "Pending Approval",
      },
    ];

    if (props.courseData) {
      props.courseData.staff_records.map((x) => {
        x.name_email = x.name + " - " + x.email;
      });

      props.courseData.course_documents.map((x) => {
        documentList.value.push(x.file_path);
      });
    }

    const genderOptions = [
      { name: "No", id: 0 },
      { name: "Yes", id: 1 },
    ];

    const contactOptionsOptions = ["Email", "Message", "Phone"];

    const resetData = () => {
      emit("refetch-data");
    };
    const resetuserData = () => {
      props.clientData = JSON.parse(JSON.stringify({}));
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    const userOptions = ref([]);

    const fetchOptions = () => {
      store
        .dispatch("app/fetchOptionList", {
          license_authority: true,
          course_organization: true,
          user: true,
        })
        .then((response) => {
          licenseAuthorityOptions.value = response.data.license_authorities;
          licenseAuthorityOptions.value.unshift({
            id: "b",
            authority_name: "Both",
            training_name: "Both",
          });
          if (props.courseData.authority_id == null) {
            props.courseData.authority_id = "b";
            props.courseData.license_authority_name = "Both";
            props.courseData.license_authority_id = "Both";
          }

          courseOrganizationOptions.value = response.data.course_organizations;
          userOptions.value = response.data.users;
        })
        .catch((error) => {
          if (error.response.status === 400) {
            licenseAuthorityOptions.value = undefined;
            courseOrganizationOptions.value = undefined;
          }
        });
    };

    onMounted(() => {
      fetchOptions();
      console.log(props.courseData);
    });

    return {
      loading,
      genderOptions,
      licenseAuthorityOptions,
      courseOrganizationOptions,
      documentList,
      approved_options,
      resetData,
      refFormObserver,
      getValidationState,
      resetForm,
      resetuserData,
      userOptions,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.staff-badge {
  padding: 0.75rem 1.5rem;
  font-size: 14px;
  white-space: break-spaces;
  text-align: left;
  background-color: #388de7;
}

.teamleader_selected {
  background: #110f6a;
  padding: 3px 10px;
  border-radius: 3px;
  color: white;
}

.addParent {
  position: relative;
}

.addChildren {
  position: absolute;
  right: -5px;
  bottom: 35%;
  padding: 2px !important;
  background: #013e79;
  border-radius: 15px;
  color: white;
}

.removeChildren {
  position: absolute;
  right: -5px;
  bottom: 35%;
  padding: 2px !important;
  background: #d14f4f;
  border-radius: 15px;
  color: white;
}

.delete-button {
  width: 100%;
}
</style>
