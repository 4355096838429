<template>
  <component :is="courseData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="courseData === undefined">
      <h4 class="alert-heading">Error fetching course data</h4>
      <div class="alert-body">
        No course found with this course id. Check
        <b-link class="alert-link" :to="{ name: 'users-training-record' }">
          Course List
        </b-link>
        for other courses.
      </div>
    </b-alert>

    <b-tabs v-if="courseData" class="tabs-primary" pills>
      <!-- Tab: Information -->
      <b-tab active>
        <template #title>
          <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Course Information</span>
        </template>
        <course-edit-tab
          :course-data="courseData"
          @refetch-data="refetchData"
          class="mt-2 pt-75"
        />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import userStoreModule from "../userStoreModule";
import CourseEditTab from "./CourseEditTab.vue";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    CourseEditTab,
  },
  setup() {
    const courseData = ref(null);

    const USER_APP_STORE_MODULE_NAME = "app-user";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const refetchData = () => {
      store
        .dispatch("app-user/fetchCourse", { id: router.currentRoute.params.id })
        .then((response) => {
          console.log("get user response", response);
          courseData.value = response.data.course_record;
        })
        .catch((error) => {
          console.log("error when fetching user", error);
          if (error.response.status === 400) {
            courseData.value = undefined;
          }
        });
    };

    refetchData();

    return {
      courseData,
      refetchData,
    };
  },
};
</script>

<style></style>
